import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ClientService } from '../../../app/services/client.service';

@Component({
    selector: 'app-app-landing',
    templateUrl: './app-landing.component.html',
    styleUrls: ['./app-landing.component.scss']
})
export class AppLandingComponent implements OnInit {

    currentTab = 'inmuebles';
    form: FormGroup;
    formContacto: FormGroup;
    inmuebles;
    contratos;
    negocios;
    vehiculos;
    mapsURL = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1129.6045018298355!2d-63.1742984309303!3d-17.76717121431539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93f1e703bb279a97%3A0xda6ee9d5184d9de!2sTuTramiteYa.com!5e0!3m2!1ses-419!2sar!4v1644433964715!5m2!1ses-419!2sarh6MhasedGPp8`;
    
    constructor( private titleService: Title,
                 public sanitizer: DomSanitizer, 
                 private fb: FormBuilder,
                 public client: ClientService,
                 public cd: ChangeDetectorRef ) {
        this.titleService.setTitle('TuTramiteYA');
    }



    ngOnInit() {

        this.form = this.fb.group({
            inmueble: [''],
            contrato: [''],
            vehiculo: [''],
            negocio: [''],
          });

        this.formContacto = this.fb.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required]],
            phone: ['', [Validators.required]],
            subject: ['', [Validators.required]],
            message: ['', [Validators.required]],
          });
 
        this.client.get('/categories').subscribe(
            data => {
              console.log(data);
              for (let index = 0; index < data['data'].length; index++) {
                  if (data['data'][index].name == "Inmuebles") {
                      this.inmuebles = data['data'][index]['subcategories'];
                  }
                  if (data['data'][index].name == "Vehículos") {
                      this.vehiculos = data['data'][index]['subcategories'];
                  }
                  if (data['data'][index].name == "Contratos") {
                      this.contratos = data['data'][index]['subcategories'];
                  }
                  if (data['data'][index].name == "Negocios") {
                      this.negocios = data['data'][index]['subcategories'];
                  }       
              }
              this.cd.detectChanges();
            },
            err => {
              console.log(err);
            }
          )
    }

    sendTramite(value, tab){
        console.log(tab + ': ' +value);
    }

    save(){
        if (this.formContacto.valid) {
            console.log(this.formContacto.value);
            this.formContacto.reset();
        }else{
            console.log('error');
        }
    }

}