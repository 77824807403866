<!-- Start Footer Area -->
<footer class="footer-area">

    <!-- <div class="container copyright-area hidden-web footer-web">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/img/tutramiteya1.png" alt="image">
            </div>

            <div class="col-lg-6 col-md-6">
                <ul>
                    <li><a class="hidden-web" routerLink="/">Términos y Condiciones</a></li>
                </ul>
            </div>
        </div>
    </div> -->

    <div class="copyright-area" style="background-color: #07486b;">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6">
                    <img src="assets/img/tutramiteya1.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Términos y Condiciones</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<div class="back-to-top">Top</div>
<!-- End Back Top top -->