<!-- Start Navbar Area -->
<header id="header">
    <div class="crake-nav {{location == '/digital-marketing' ? 'marketing-navbar' : ''}}">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand navbar-custom" routerLink="/">
                    <img src="assets/img/tutramiteya.png" alt="logo">
                </a>

                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav nav ml-auto">

                        <li><a routerLink="/about-us" routerLinkActive="active">Inicio</a></li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown">Servicios</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a routerLink="/about-us" routerLinkActive="active" class="nav-link">Inmuebles</a></li>

                                <li class="nav-item"><a routerLink="/features" routerLinkActive="active" class="nav-link">Vehículos</a></li>

                                <li class="nav-item"><a routerLink="/services" routerLinkActive="active" class="nav-link">Contratos</a></li>

                                <li class="nav-item"><a routerLink="/gallery" routerLinkActive="active" class="nav-link">Negocios</a></li>

                            </ul>
                        </li>

                        <li><a routerLink="/gallery" routerLinkActive="active">Nosotros</a></li>
                        <li><a routerLink="/gallery" routerLinkActive="active">Contacto</a></li>
                        <li><a routerLink="/gallery" routerLinkActive="active">Usuarios</a></li>

                        <li><a href="tel:+59179888808" routerLinkActive="active"><i class="icofont-phone" style="color: #07486b;"></i> +59179888808</a> <a href="https://www.facebook.com/TuTramiteYaBo/" target="_blank" routerLinkActive="active"><i class="icofont-facebook" style="color: #07486b;"></i></a></li>
                        <!-- <li><a href="https://www.facebook.com/TuTramiteYaBo/" target="_blank" routerLinkActive="active"><i class="icofont-facebook" style="color: #07486b;"></i></a></li> -->
                    </ul>
                </div>

                <!-- <div class="mr-auto others-option">
                    <ul class="navbar-nav">
                        <li class="header-search-box">
                            <a href="javascript:void(0)" id="header-search-main" title="Search">
                                <i class="icofont-search-2"></i>
                            </a>
                        </li>

                        <li data-toggle="modal" data-target="#myModal2">
                            <div class="side-menu">
                                <span class="bar-1"></span>
                                <span class="bar-2"></span>
                                <span class="bar-3"></span>
                            </div>
                        </li>
                    </ul>
                </div> -->
            </nav>
        </div>
    </div>
</header>
<!-- End Navbar Area -->

<app-sidebar></app-sidebar>

<!-- Start Search Box -->
<div id="header-search" class="header-search">
    <button type="button" class="close">×</button>
    <form class="header-search-form">
        <input type="search" value="" placeholder="Type here........" />
        <button type="submit" class="btn btn-primary">Search</button>
    </form>
</div>
<!-- End Search Box -->